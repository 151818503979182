<template>
  <div class="weui-cell" :class="disabled!==undefined?'disabled':''">
    <div class="weui-cell__bd" :class="required?'require':''">{{ text }}</div>
    <div class="weui-cell__ft">
      <input
        class="weui-switch"
        v-model="input"
        type="checkbox"
        @input="change"
        :disabled="disabled"
      />
      <slot></slot>
    </div>
    
  </div>
</template>

<script>
import mixins from "@/mixins/form";
export default {
  props: ["type", "placeholder", "text", "value", "model","disabled","required"],
  data() {
    return {
      input: "",
    };
  },
  
  mixins:[mixins],
  mounted() {
    this.input = !!this.model;
  },
  methods: {
    change() {
      this.$emit("update:model", !this.input);
    },
  },
};
</script>

<style >
.weui-cell__ft{
    display: flex;
    align-items: center;
}
.disabled{
    opacity: 0.5;
}

</style>