<template>
  <div class="weui-cell" @click="picker">
    <div class="weui-cell__bd" :class="required ? 'require' : ''">
      {{ text }}
    </div>
    <div class="weui-cell__ft">
      <span :class="!input ? 'placeholder' : ''">{{
        input || placeholder
      }}</span>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import mixins from "@/mixins/form";
export default {
  props: ["placeholder", "text", "value", "model", "options", "required"],
  data() {
    return {
      input: "",
    };
  },
  mounted() {
    if (this.options) {
      this.options.forEach((item) => {
        if (item.value == this.model) {
          this.input = item.label;
        }
      });
    }
  },
  watch: {
    model() {
      if (this.model == "") {
        this.input = "";
      }
      if (this.options) {
        this.options.forEach((item) => {
          if (item.value == this.model) {
            this.input = item.label;
          }
        });
      }
    },
    options() {
      if (this.model == "") {
        this.input = "";
      }
      if (this.options) {
        this.options.forEach((item) => {
          if (item.value == this.model) {
            this.input = item.label;
          }
        });
      }
    },
  },
  methods: {
    picker() {
      this.$weui.picker(this.options, {
        className: "custom-classname",
        container: "body",
        defaultValue: [0],
        onConfirm: (result) => {
          this.input = result[0].label;
          this.$emit("update:model", result[0].value);
        },
        id: "singleLinePicker",
      });
    },
  },
};
</script>

<style>
.placeholder {
  font-size: 0.16rem;
  color: #aaa;
}
</style>