<template>
  <div class="weui-cell" :class="disabled !== undefined ? 'disabled' : ''">
    <div class="weui-cell__bd" :class="{ require: flag }">{{ text }}</div>
    <div class="weui-cell__ft">
      <input
        class="weui-input"
        :type="type"
        v-model="input"
        :placeholder="placeholder"
        @input="change"
        :disabled="disabled"
        :readonly="readonly"
      />
      <slot></slot>
    </div>
  </div>
</template>

<script>
import mixins from "@/mixins/form";
export default {
  props: [
    "type",
    "placeholder",
    "text",
    "value",
    "model",
    "disabled",
    "readonly",
    "required",
  ],
  data() {
    return {
      input: "",
      flag: true,
    };
  },

  mixins: [mixins],
  mounted() {
    this.input = this.model;
    this.flag = !!this.required;
  },
  methods: {
    change() {
      this.$emit("update:model", this.input);
    },
  },
  watch:{
    model(){
      this.input = this.model
    },
   required(){
      this.flag = !!this.required
   }
  }
};
</script>

<style >
.weui-cell__ft {
  display: flex;
  align-items: center;
}
.disabled {
  opacity: 0.5;
}
</style>