<template>
  <div class="form">
    <!-- 组1 -->
    <div class="weui-cells">
      <apicker
        :options="temperature"
        text="当前体温"
        :value="form.temperature"
        :model.sync="form.temperature"
        placeholder="请选择"
      ></apicker>
      <adate
        text="测量时间"
        :model.sync="form.time"
        placeholder="请选择"
      ></adate>
      <aswitch text="有无不适" :model.sync="form.isAbnormal"></aswitch>
    </div>
    <apicker
      :options="types"
      text="症状"
      :model.sync="form.abnormalId"
      placeholder="请选择"
      v-if="form.isAbnormal"
    ></apicker>
    <ainput
      text="症状详情"
      :model.sync="form.remark"
      placeholder="请输入"
      v-if="detailControl"
    ></ainput>

    <div class="btn-area" style="padding-bottom: 0.2rem">
      <button class="weui-btn weui-btn_primary" @click="submit">确认</button>
    </div>

    <div class="weui-cells__title" v-if="!isEdit">历史记录</div>
    <div class="content" v-if="!isEdit">
      <el-timeline>
        <el-timeline-item
          v-for="(item, index) in history"
          :key="index"
          :timestamp="item.time ? item.time.substr(0, 10) : ''"
        >
          <div class="tit">
            <p>体温{{ item.temperature }}度</p>
            <p v-if="item.isAbnormal == 1">
              {{ item.abnormal ? item.abnormal.name==='其他'?item.remark:item.abnormal.name: "" }}
            </p>
          </div>
        </el-timeline-item>
      </el-timeline>
      <p class="viewAllBtn" @click="goHistory">点击查看全部</p>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import ainput from "@/components/form/input";
import apicker from "@/components/form/picker";
import aradio from "@/components/form/radio";
import aswitch from "@/components/form/switch";
import adate from "@/components/form/datepicker";
import { Timeline, TimelineItem } from "element-ui";
import { TempList, TempSave, tempDetail, EditTemp } from "@/api/api";
import time from "@/mixins/time";
Vue.use(Timeline);
Vue.use(TimelineItem);
import WebView from "@/mixins/webview";
export default {
  data() {
    return {
      name: "",
      types: [],
      form: {
        mobile: "",
        isAbnormal: 0,
        abnormalId: "",
        temperature: 36.5,
        time: this.nowTime(),
      },
      history: [],
      tflag: false,
      temperature: [],
      isEdit: false,
    };
  },
  components: {
    ainput,
    apicker,
    aradio,
    aswitch,
    Timeline,
    TimelineItem,
    adate,
  },
  created() {
    let temperature = [];
    for (let i = 355; i <= 400; i++) {
      temperature.push({
        label: i / 10,
        value: i / 10,
      });
    }
    this.temperature = temperature;
  },
  mounted() {
    this.isEdit = Boolean(this.$route.query.isEdit);
    document.title = this.isEdit ? "编辑体温信息" : "体温信息";
    this.$route.query.data && (this.form = JSON.parse(this.$route.query.data));
    this.id = this.$route.query.id;
    this.isWebView();
    this.form.mobile = this.$store.state.userInfo.mobile;
    this.getList();
    this.getHistory();
    let _this = this;
    this.$weui.slider("#slider", {
      step: 2,
      defaultValue: 30,
      onChange: function (percent) {
        let initNum = 35;
        _this.form.temperature = 35 + Math.ceil(parseInt(percent) / 2) / 10;
      },
    });
  },
  computed: {
    detailControl() {
      let id = this.form.abnormalId;
      console.log(id);
      let reslut =
        this.types.find((item) => {
          return item.value === id;
        }) || {};
      if (reslut.label === "其他") return true;
      return false;
    },
  },
  mixins: [WebView, time],
  methods: {
    goHistory() {
      let mobile = this.$store.state.userInfo.mobile;
      this.$router.push("/detailList/" + mobile + "?type=temp");
    },
    submit() {
      if (this.form.temperature < 35 || this.form.temperature > 45) {
        this.$weui.toast("体温异常", {
          duration: 1500,
          className: "error",
        });
        return;
      }
      if (this.detailControl) {
        if (!this.form.remark) {
          this.$weui.toast("请输入症状详情", {
            duration: 1500,
            className: "error",
          });
          return;
        }
      }
      let form = { ...this.form };
      form.isAbnormal = this.form.isAbnormal ? 1 : 0;
      if (this.id) {
        EditTemp(this.id, form).then((res) => {
          this.$weui.toast("提交成功", {
            duration: 1500,
            className: "custom-classname",
          });
          if (window.__wxjs_environment === "miniprogram") {
            setTimeout(() => {
              wx.miniProgram.navigateBack();
            }, 2000);
          } else {
            this.$router.go(-1);
          }
        });
      } else {
        TempSave(form).then((res) => {
          this.$weui.toast("提交成功", {
            duration: 1500,
            className: "custom-classname",
          });
          if (window.__wxjs_environment === "miniprogram") {
            setTimeout(() => {
              wx.miniProgram.navigateBack();
            }, 2000);
          } else {
            this.$router.go(-1);
          }
        });
      }
    },
    getHistory() {
      tempDetail({ mobile: this.$store.state.userInfo.mobile }).then((res) => {
        this.history = res.data.data.slice(0, 3);
      });
    },
    async getList() {
      let res = await TempList();
      let arr = res.data.data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      this.types = arr;
    },
    trigger() {
      this.tflag = !this.tflag;
    },
  },
};
</script>

<style scoped>
.weui-slider__handler {
  width: 18px;
  height: 18px;
  margin-top: -9px;
  margin-left: -9px;
}
.content {
  padding: 0.2rem 0.3rem;
}
.weui-cells__title {
  color: #2f7dcd;
}
</style>