<template>
  <div class="weui-cell" :class="disabled !== undefined ? 'disabled' : ''">
    <div class="weui-cell__bd" :class="required?'require':''">{{ text }}</div>
    <div class="weui-cell__ft">
      <span
        class="radio"
        :class="current === index?'active':''"
        v-for="(item, index) in options"
        @click="change(item, index)"
        >{{ item.label }}</span
      >
      <slot></slot>
    </div>
  </div>
</template>

<script>
import mixins from "@/mixins/form";
export default {
  props: ["options", "text", "model", "disabled","required"],
  data() {
    return {
      input: "",
      current: "",
    };
  },

  watch:{
      
  },
  mounted() {
    if(this.options){
      this.options.forEach((item,index) => {
        if(this.model === item.value){
            this.current = index
        }
      });
    }
  },
  methods: {
    change(item, index) {
      this.current = index;
      this.$emit("update:model", item.value);
    },
  },
};
</script>

<style scoped>
.weui-cell__ft {
  display: flex;
  align-items: center;
  min-width: 1.3rem;
  justify-content: space-between;
}
.disabled { 
  opacity: 0.5;
}
.radio {
  font-size: 0.12rem;
  flex: 1;
  text-align: center;
  background: #EDEDED;
  height: 0.28rem;
  line-height: 0.28rem;
  border-radius: 0.14rem;
  position: relative;
  z-index: 8;
}
.radio:first-child{
  transform: translateX(30%);
  padding-right: 0.06rem;
}
.radio:last-child{
  padding-left: 0.06rem;
}
.radio.active{
    background: #2f7dcd;
    font-weight: bold;
    color: #fff;
    z-index: 10;
}
</style>